var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.active)?_c('v-speed-dial',{attrs:{"direction":"bottom","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#3d70b2","dark":"","fab":""}},on),[(_vm.fab)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,3167280461)},[_c('span',[_vm._v(_vm._s(_vm.fab ? "Close" : "Program Menu"))])])]},proxy:true}],null,false,1466706815),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#3bb273"},on:{"click":_vm.restartProgram}},on),[_c('v-icon',[_vm._v("mdi-restart")])],1)]}}],null,false,1045036278)},[_c('span',[_vm._v("Restart")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#e15554"},on:{"click":_vm.stopProgram}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,false,136535142)},[_c('span',[_vm._v("Stop")])])],1):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"#3d70b2"},on:{"click":_vm.selectProgram}},on),[_c('v-icon',[_vm._v("mdi-bookmark")])],1)]}}])},[_c('span',[_vm._v("Select Program")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }